<template>
  <div style="margin-top: -5px">
    <b-card no-body class="mb-0 table-no-top table-text-nowrap">
      <b-table
        ref="refProfileListTable"
        class="position-relative mb-25 overflow-auto last-child-nb table-bottom-round table-small table-alternate-bg"
        :items="fetchStudentSections"
        responsive
        :fields="sectionFields"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text=""
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Course -->
        <template #cell(name)="data" #cell(index)="index">
          <div class="d-flex align-items-center" style="gap: 0.625rem" v-if="data.item.course">
            <div class="d-flex justify-content-center align-items-center card card-bg mb-0" style="width: 2.25rem; height: 2.25rem">
              <h4 class="mb-0">{{ data.item.index }}</h4>
            </div>
            <b-avatar
              size="36"
              :src="data.item.course.avatar"
              :text="avatarText(data.item.course.name)"
              :variant="`light-info`"
              :to="{ name: 'apps-roster-course-view', params: { id: data.item.course.id } }"
              style="margin-top: 2px"
              rounded
            />
            <div class="d-flex flex-column" style="gap: 0">
              <div class="d-flex align-items-center font-weight-bold text-nowrap text-white mb-0">
                <div
                  class="cursor-pointer"
                  @click="$router.push({ name: 'apps-roster-course-section-view', params: { id: data.item.id } })"
                >
                  {{ resolveCourseSectionName(data.item) }}
                </div>
                <div v-if="sectionField(data.item.profile, data.item.id, 'replacedBy')" class="ml-50">
                  <feather-icon
                    @click="redirectToReplaced(sectionField(data.item.profile, data.item.id, 'replacedBy'))"
                    icon="InfoIcon"
                    class="text-warning cursor-pointer"
                    :id="`sr-${data.item.id}-replaced-tooltip`"
                    style="margin-bottom: 2px; z-index: 9"
                  />
                  <b-tooltip
                    placement="right"
                    @click="redirectToReplaced(sectionField(data.item.profile, data.item.id, 'replacedBy'))"
                    :title="`Replaced by another section`"
                    class="cursor-pointer"
                    :target="`sr-${data.item.id}-replaced-tooltip`"
                  />
                </div>
              </div>
              <b-link
                :to="{ name: 'apps-roster-course-section-view', params: { id: data.item.id } }"
                class="d-flex align-items-center text-muted text-nowrap"
              >
                <lucide-icon name="Book" style="width: 14px; height: 14px; margin-right: 1px" />
                <small> {{ data.item.course.name }}</small>
              </b-link>
            </div>
          </div>
        </template>

        <!-- Column: Attributes - Program -->
        <template #cell(fundingSource)="data">
          <b-badge :variant="`light-${resolveProfileProgramVariant(data.item.profile.attributes.fundingSource)}`" class="text-capitalize">
            {{ title(data.item.profile.attributes.fundingSource) }}
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`${resolveProfileSectionStatusVariant(sectionField(data.item.profile, data.item.id, 'status'))}`"
            class="text-capitalize"
          >
            {{ title(sectionField(data.item.profile, data.item.id, 'status')) }}
          </b-badge>
        </template>

        <!-- Column: Transfer of Credit -->
        <template #cell(toc)="data">
          <b-badge
            :variant="`light-${resolveProfileYNVariant(
              sectionField(data.item.profile, data.item.id, 'toc') ? sectionField(data.item.profile, data.item.id, 'toc').enabled : 'no',
            )}`"
            class="text-capitalize"
          >
            {{
              title(
                sectionField(data.item.profile, data.item.id, 'toc') && sectionField(data.item.profile, data.item.id, 'toc').enabled
                  ? 'Yes'
                  : 'No',
              )
            }}
          </b-badge>
        </template>

        <!-- Column: Course Exam Voucher -->
        <template #cell(examVoucher)="data">
          <b-badge
            :variant="`light-${resolveProfileYNVariant(sectionField(data.item.profile, data.item.id, 'examVoucher'))}`"
            class="text-capitalize"
          >
            {{ title(sectionField(data.item.profile, data.item.id, 'examVoucher')) }}
          </b-badge>
        </template>

        <!-- Column: Course Exam Taken? -->
        <template #cell(examTaken)="data">
          <b-badge
            :variant="`light-${resolveProfileYNVariant(sectionField(data.item.profile, data.item.id, 'examTaken'))}`"
            class="text-capitalize"
          >
            {{ title(sectionField(data.item.profile, data.item.id, 'examTaken')) }}
          </b-badge>
        </template>

        <!-- Column: Attributes - Retake -->
        <template #cell(retake)="data">
          <b-badge
            v-if="data.item && data.item.profile.sections[data.item.id] && data.item.profile.sections[data.item.id].retake"
            :variant="`light-${resolveProfileYNVariant(data.item.profile.sections[data.item.id].retake)}`"
            class="text-capitalize"
          >
            {{ title(data.item.profile.sections[data.item.id].retake) }}
          </b-badge>
          <b-badge v-else variant="light-danger" class="text-capitalize">n/a</b-badge>
        </template>

        <!-- Column: Course Exam Passed? -->
        <template #cell(examPassed)="data">
          <b-badge
            :variant="`light-${resolveProfileYNVariant(sectionField(data.item.profile, data.item.id, 'examPassed'))}`"
            class="text-capitalize"
          >
            {{ title(sectionField(data.item.profile, data.item.id, 'examPassed')) }}
          </b-badge>
        </template>

        <!-- Column: Course Exam Passed? -->
        <template #cell(labs)="data">
          <b-badge
            :variant="`light-${resolveProfileYNVariant(sectionField(data.item.profile, data.item.id, 'labCompleted'))}`"
            class="text-capitalize"
          >
            {{ title(sectionField(data.item.profile, data.item.id, 'labCompleted')) }}
          </b-badge>
        </template>

        <!-- Column: Attributes - Payment Status -->
        <!-- <template #cell(paymentStatus)="data">
  <b-badge :variant="`${resolveProfilePaymentStatusVariant(data.item.profile.attributes.paymentStatus)}`" class="text-capitalize">
    {{ title(data.item.profile.attributes.paymentStatus) }}
  </b-badge>
</template> -->

        <!-- Column: Attributes - Uploaded Resume -->
        <template #cell(uploadedResume)="data">
          <div class="d-flex align-items-center">
            <feather-icon
              :id="`profile-row-${data.item.id}-upload-resume-icon`"
              icon="UploadIcon"
              class="cursor-pointer mr-25"
              size="16"
              @click="startUpload(data.item, 'enrollment', 'resume')"
            />

            <b-tooltip title="Upload Resume" class="cursor-pointer" :target="`profile-row-${data.item.id}-upload-resume-icon`" />

            <b-badge :variant="`light-${resolveProfileYNVariant(data.item.profile.attributes.uploadedResume)}`" class="text-capitalize">
              {{ title(data.item.profile.attributes.uploadedResume) }}
            </b-badge>
          </div>
        </template>

        <!-- Column: Attributes - Uploaded COE -->
        <template #cell(uploadedCoe)="data">
          <div class="d-flex align-items-center">
            <feather-icon
              :id="`profile-row-${data.item.id}-upload-coe-icon`"
              icon="UploadIcon"
              class="cursor-pointer mr-25"
              size="16"
              @click="startUpload(data.item, 'enrollment', 'coe')"
            />

            <b-tooltip title="Upload COE" class="cursor-pointer" :target="`profile-row-${data.item.id}-upload-coe-icon`" />

            <b-badge :variant="`light-${resolveProfileYNVariant(data.item.profile.attributes.uploadedCoe)}`" class="text-capitalize">
              {{ title(data.item.profile.attributes.uploadedCoe) }}
            </b-badge>
          </div>
        </template>

        <!-- Column: Attributes - Uploaded Transcript -->
        <template #cell(uploadedTranscript)="data">
          <div class="d-flex align-items-center">
            <feather-icon
              :id="`profile-row-${data.item.id}-upload-transcript-icon`"
              icon="UploadIcon"
              class="cursor-pointer mr-25"
              size="16"
              @click="startUpload(data.item, 'transcript', 'prior')"
            />

            <b-tooltip title="Upload Transcript" class="cursor-pointer" :target="`profile-row-${data.item.id}-upload-transcript-icon`" />

            <b-badge :variant="`light-${resolveProfileYNVariant(data.item.profile.attributes.uploadedTranscript)}`" class="text-capitalize">
              {{ title(data.item.profile.attributes.uploadedTranscript) }}
            </b-badge>
          </div>
        </template>

        <!-- Column: Attributes - VA Submission -->
        <template #cell(submittedToVa)="data">
          <div class="d-flex align-items-center">
            <feather-icon
              :id="`profile-row-${data.item.id}-update-submission`"
              icon="CheckCircleIcon"
              class="cursor-pointer mr-25"
              size="16"
              @click="markProfileAsSubmitted(data.item)"
              v-if="data.item.profile.attributes.submittedToVa === 'no'"
            />

            <b-tooltip
              title="Mark as Submitted"
              class="cursor-pointer"
              :target="`profile-row-${data.item.id}-update-submission`"
              v-if="data.item.profile.attributes.submittedToVa === 'no'"
            />

            <b-badge :variant="`light-${resolveProfileYNVariant(data.item.profile.attributes.submittedToVa)}`" class="text-capitalize">
              {{ title(data.item.profile.attributes.submittedToVa) }}
            </b-badge>
          </div>
        </template>

        <!-- Column: Total Students -->
        <template #cell(sessionDate)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              {{ moment(data.item.sessionDate).format('L') }}
            </span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="primary" class="dropdown-edit-button">
            <template #button-content>
              <div class="p-50">
                <feather-icon icon="MoreHorizontalIcon" size="21" />
              </div>
            </template>

            <b-dropdown-item
              v-if="$can('roster.profile.update')"
              @click="
                $router.push({ name: 'apps-roster-profile-edit', params: { id: data.item.profile.id }, query: { section: data.item.id } })
              "
            >
              <div class="d-flex align-items-center justify-content-between w-100">
                <span>Edit</span>
                <feather-icon icon="Edit3Icon" size="15" class="ml-50 text-muted" />
              </div>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="
                data.item.profile.session &&
                data.item.profile.session.type === 'pathway' &&
                sectionField(data.item.profile, data.item.id, 'status') !== 'replaced'
              "
              @click="openSectionReplaceModal(data.item)"
            >
              <div class="d-flex align-items-center justify-content-between w-100">
                <span>Replace</span>
                <feather-icon icon="MoveIcon" />
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>

    <ProfileListAddV2WithReplace
      v-if="isReplaceSectionOpen"
      :create-profile-active.sync="isReplaceSectionOpen"
      :selected-course-section="selectedSection"
      :redirect-on-complete="false"
      :profile-id.sync="selectedProfile.id"
      :profile.sync="selectedProfile"
      :selected-student.sync="selectedStudent"
      :restrict-to-course="selectedSection.course.id"
      @refetch-data="() => $refs.refProfileListTable.refresh()"
      mode="replace"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from 'bootstrap-vue';
import { avatarText, title } from '@core/utils/filter';
import store from '@/store';
import { onUnmounted, ref } from '@vue/composition-api';
import studentStoreModule from '@/store/student/studentStoreModule';
import moment from 'moment';
import useCourseSections from '@/views/apps/roster/courses/sections/useCourseSections';
import { momentTzFormatted } from '@/libs/utils/time';
import { hexToRGB } from '@/libs/utils/color';
import useProfiles from '@/views/apps/roster/profile/useProfiles';
import VueContext from 'vue-context';
import vSelect from 'vue-select';
import ProfileListAddV2WithReplace from '@/views/apps/roster/profile/create/ProfileListAddv2.vue';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    VueContext,
    vSelect,
    ProfileListAddV2WithReplace,
  },
  props: {
    studentId: {
      type: String,
      required: true,
    },
    profileId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isReplaceSectionOpen: false,
      selectedProfile: undefined,
      selectedStudent: undefined,
      selectedSection: undefined,
    };
  },
  methods: {
    redirectToReplaced(section) {
      return this.$router.push({ name: 'apps-roster-course-section-view', params: { id: section } });
    },
    sectionField(profile, section, field) {
      const sectionId = section;

      if (!profile.sections || !profile.sections[sectionId]) {
        return 'na';
      }

      return profile.sections[sectionId][field];
    },

    //  { ...section, profile, student }
    openSectionReplaceModal(data) {
      this.isReplaceSectionOpen = true;

      const { profile, student, ...section } = data;

      this.selectedProfile = profile;
      this.selectedStudent = student;
      this.selectedSection = section;

      console.log('> using selected', {
        profile: this.selectedProfile,
        student: this.selectedStudent,
        section: this.selectedSection,
      });
    },
  },
  setup(props) {
    const sectionFields = ref([
      { key: 'name', label: 'Section Name', sortable: true },
      { key: 'status', sortable: true },
      { key: 'toc', label: 'ToC', sortable: true },
      { key: 'retake', label: 'Retake', sortable: false },
      { key: 'examVoucher', sortable: true },
      { key: 'examTaken', sortable: true },
      { key: 'examPassed', sortable: true },
      { key: 'labs', sortable: true },
      { key: 'actions' },
    ]);
    const sectionsData = ref({});
    const currentPage = ref(1);
    const perPage = ref(100);
    const totalSections = ref(0);
    const totalPageCount = ref(1);

    const isSortDirDesc = ref(false);
    const sortBy = ref('startDate');

    const fetchStudentSections = (ctx) => {
      const sbv = sortBy.value === 'id' ? '_id' : sortBy.value;
      const sb = `${sbv}:${isSortDirDesc.value ? 'desc' : 'asc'}`;

      const promise = store.dispatch('studentStoreModule/fetchStudentSections', {
        id: props.studentId,
        queryParams: {
          limit: perPage.value,
          page: currentPage.value,
          populate: 'student course',
          sortBy: sb,
          profileIds: [props.profileId],
          // session: cid,
        },
      });

      return promise
        .then((response) => {
          const { results, totalResults, totalPages } = response.data;

          const cid = props.cohortId;

          const resultsMapped = results.map((row, index) => {
            row.index = index + 1;
            return row;
          });

          sectionsData.value = resultsMapped;
          totalSections.value = totalResults;
          totalPageCount.value = totalPages;
          return resultsMapped;
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === 404) {
            sectionsData.value = undefined;
          }
        });
    };

    const resolveCourseStatusVariant = (status) => {
      if (status === 'created') return 'secondary';
      if (status === 'enrolling') return 'info';
      if (status === 'waiting') return 'warning';
      if (status === 'live') return 'info';
      if (status === 'completed') return 'success';
      return 'info';
    };

    const resolveCourseSectionStartDate = (courseSection) => {
      return momentTzFormatted(courseSection.startDate, 'MMMM Do YYYY');
    };

    const {
      resolveUserRoleVariant,
      resolveCourseSectionName,
      resolveCourseSectionStatusVariant,
      resolveCourseSectionStatusIcon,
      resolveCourseSectionVisibilityVariant,
      resolveCourseSectionVisibilityIcon,
      resolveCourseSectionDateMessage,
      resolveCourseSectionDateMessageIcon,
      resolveCourseSectionDateMessageVariant,
    } = useCourseSections();

    const {
      perPageOptions,

      resolveProfileVisibilityVariant,
      resolveProfileStatusVariant,
      resolveProfileLocationStatus,
      resolveProfileSectionStatusVariant,
      resolveProfilePaymentStatusVariant,
      resolveProfileProgramVariant,
      resolveProfileYNVariant,
    } = useProfiles();

    return {
      fetchStudentSections,
      sectionFields,
      sectionsData,
      avatarText,
      title,
      moment,
      resolveCourseStatusVariant,

      resolveUserRoleVariant,
      resolveCourseSectionName,
      resolveCourseSectionStatusVariant,
      resolveCourseSectionStatusIcon,
      resolveCourseSectionVisibilityIcon,
      resolveCourseSectionVisibilityVariant,
      resolveCourseSectionDateMessage,
      resolveCourseSectionDateMessageIcon,
      resolveCourseSectionDateMessageVariant,
      resolveCourseSectionStartDate,

      perPageOptions,

      resolveProfileVisibilityVariant,
      resolveProfileStatusVariant,
      resolveProfileSectionStatusVariant,
      resolveProfilePaymentStatusVariant,
      resolveProfileProgramVariant,
      resolveProfileYNVariant,

      sortBy,
      isSortDirDesc,
      currentPage,
      perPage,
      totalSections,
      totalPageCount,
    };
  },
};
</script>
