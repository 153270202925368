import { ref, watch, computed } from '@vue/composition-api';
import { title } from '@core/utils/filter';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import moment from 'moment';
import store from '@/store';

export default function useCourseSectionStudentsList(courseSectionId) {
  // Use toast
  const toast = useToast();

  const refStudentsListTable = ref(null);
  const isCurrent = ref(true);

  const tableColumns = [
    // { key: 'multiSelect', label: '', stickyColumn: true, variant: 'primary', sortable: false },
    {
      key: 'multiSelect',
      label: '',
      tdClass: 'td-checkbox checkbox-self sticky-checkbox',
      thClass: 'td-checkbox checkbox-self sticky-checkbox',
      variant: 'primary',
      stickyColumn: true,
      sortable: false,
    },

    {
      key: 'name',
      sortable: true,
      stickyColumn: true,
      variant: 'primary',
      tdClass: 'td-checkbox sticky-primary',
      thClass: 'td-checkbox sticky-primary',
    },
    { key: 'session', label: 'Cohort', sortable: true },
    // { key: 'email', label: 'Email', sortable: true },
    { key: 'status', label: 'Section Status', sortable: true },
    { key: 'modality', sortable: true },
    { key: 'studentStatus', sortable: true },
    { key: 'fundingSource', label: 'Funding', sortable: true },
    { key: 'compliance', sortable: true },
    { key: 'makeup', sortable: false },
    { key: 'retake', sortable: false },
    { key: 'toc', label: 'ToC', sortable: true },
    { key: 'examVoucher', sortable: true },
    { key: 'examTaken', sortable: true },
    { key: 'examPassed', sortable: true },
    { key: 'labs', sortable: true },
    { key: 'uploadedResume', label: 'Resume', sortable: true },
    { key: 'uploadedCoe', label: 'COE', sortable: true },
    { key: 'uploadedTranscript', label: 'Transcript', sortable: true },
    { key: 'submittedToVa', label: 'VA Submission', sortable: true },
    { key: 'actions' },
  ];

  const perPage = ref(10);
  const totalProfiles = ref(0);
  const currentPage = ref(1);
  const totalPageCount = ref(0);
  const perPageOptions = [3, 5, 10, 15, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('student.fullName');
  const isSortDirDesc = ref(false);

  const idsOnPage = ref([]);

  const visibilityFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refStudentsListTable.value ? refStudentsListTable.value.localItems.length : 0;

    console.log(perPage.value, currentPage.value, localItemsCount, totalProfiles.value);

    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProfiles.value,
    };
  });

  const refetchData = () => {
    if (refStudentsListTable.value) {
      refStudentsListTable.value.refresh();
    }
  };

  watch([currentPage, perPage, searchQuery, visibilityFilter, statusFilter, isCurrent], (a) => {
    console.log("'upd", a);
    refetchData();
  });

  const fetchProfiles = (ctx, callback) => {
    console.log('fetching profiles for session', courseSectionId);
    const pw = courseSectionId;

    let sbv = sortBy.value === 'id' ? '_id' : sortBy.value;
    if (sbv === 'toc') {
      sbv = `sections.${courseSectionId}.toc.enabled`;
    } else if (sbv === 'status') {
      sbv = `sections.${courseSectionId}.status`;
    } else if (sbv === 'session') {
      sbv = 'session.startDate';
    } else if (sbv === 'compliance') {
      sbv = `compliance.sections.${courseSectionId}.scores.overall`;
    }

    const sb = `${sbv}:${isSortDirDesc.value ? 'desc' : 'asc'}`;

    const pl = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sortBy: sb,

      populate: 'student,reassignFrom,session.pathway,session.course,compliance',
    };

    if (visibilityFilter.value) {
      pl.visibility = visibilityFilter.value;
    }
    if (statusFilter.value) {
      pl.status = statusFilter.value;
    }

    if (!isCurrent.value) {
      pl.reassignFrom = courseSectionId;
    }

    store
      .dispatch('rosterStoreModule/fetchCourseSectionStudents', { courseSectionId, queryParams: pl })
      .then((response) => {
        console.log('loaded students', response.data);
        const { results, totalResults, totalPages } = response.data;
        idsOnPage.value = results.map((result) => result.id || result._id);

        callback(results);
        totalProfiles.value = totalResults;
        totalPageCount.value = totalPages;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching student list-pending',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchProfiles,
    tableColumns,
    perPage,
    currentPage,
    totalPageCount,
    totalProfiles,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refStudentsListTable,
    idsOnPage,
    isCurrent,

    refetchData,

    // Extra Filters
    visibilityFilter,
    statusFilter,
  };
}
