<template>
  <component is="div">
    <b-modal
      id="modal-gre"
      ok-title="Modify"
      centered
      size="lg"
      :title="`Update an existing grade report`"
      ref="modal"
      @hidden="() => closeEdit()"
      :visible="isEditOpen"
      @ok="() => submitReportEdit()"
      :ok-disabled="false"
      :no-close-on-backdrop="true"
    >
      <span>Scores</span>
      <b-row>
        <b-col cols="4">
          <b-form-group label="Overall" label-for="toc-hours">
            <b-input-group append="%">
              <cleave
                id="toc-hours"
                v-model="reportEdit.scores.overall"
                class="form-control"
                :raw="true"
                :options="options.score"
                placeholder="0"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Attendance" label-for="toc-hours">
            <b-input-group append="%">
              <cleave
                id="toc-hours"
                v-model="reportEdit.scores.attendance"
                class="form-control"
                :raw="true"
                :options="options.score"
                placeholder="0"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Assignments" label-for="toc-hours">
            <b-input-group append="%">
              <cleave
                id="toc-hours"
                v-model="reportEdit.scores.assignments"
                class="form-control"
                :raw="true"
                :options="options.score"
                placeholder="0"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <span>Weights</span>
      <b-row>
        <b-col cols="6">
          <b-form-group label="Attendance" label-for="toc-hours">
            <b-input-group append="%">
              <cleave
                id="toc-hours"
                v-model="reportEdit.weights.attendance"
                class="form-control"
                :raw="true"
                :options="options.weight"
                placeholder="0"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Assignments" label-for="toc-hours">
            <b-input-group append="%">
              <cleave
                id="toc-hours"
                v-model="reportEdit.weights.assignments"
                class="form-control"
                :raw="true"
                :options="options.weight"
                placeholder="0"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="w-100">
        <b-table
          :items.sync="reportEditClasses"
          sort-by="date"
          :fields="[
            { key: 'date', label: 'Date', sortable: true },
            { key: 'type', label: 'Type' },
            { key: 'checkins.attended', label: 'Checkins - Attended' },
            { key: 'checkins.required', label: 'Checkins - Required' },
          ]"
          :current-page="currentPage"
          :total-rows="totalResults"
          :per-page="perPage"
        >
          <!-- Column: Date of Class -->
          <template #cell(date)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">
                <formatted-time :timestamp="data.item.date" format="MMMM Do, YYYY" />
              </span>
            </div>
          </template>

          <!-- Column: Type -->
          <template #cell(type)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">
                {{ data.item.type }}
              </span>
            </div>
          </template>

          <!-- Column: Checkins - Required -->
          <template #cell(checkins.required)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">
                <cleave
                  :id="`class-${data.item.id}-required`"
                  v-model="data.item.checkins.required"
                  class="form-control"
                  :raw="true"
                  :options="options.checkins"
                  placeholder="0"
                />
              </span>
            </div>
          </template>

          <!-- Column: Checkins - Attended -->
          <template #cell(checkins.attended)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">
                <cleave
                  :id="`class-${data.item.id}-attended`"
                  v-model="data.item.checkins.attended"
                  class="form-control"
                  :raw="true"
                  :options="options.checkins"
                  placeholder="0"
                />
              </span>
            </div>
          </template>
        </b-table>

        <div class="mx-2 mb-75 w-100">
          <b-row>
            <b-col cols="12" md="3" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="mb-0 text-muted">{{ totalResults }} Total Entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" md="9" class="d-flex align-items-center justify-content-center justify-content-sm-end mt-1 mt-sm-0">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalResults"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-sm-0 ml-lg-50"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-row>
    </b-modal>
  </component>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue';
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import store from '@/store/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import { useToast } from 'vue-toastification/composition';
import moment from 'moment';
import { avatarText, title } from '@core/utils/filter';
import usePathwayCohorts from '@/views/apps/roster/pathways/cohorts/usePathwayCohorts';
import useCourseSections from '@/views/apps/roster/courses/sections/useCourseSections';
import vSelect from 'vue-select';
import Cleave from 'vue-cleave-component';
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import FormattedTime from '@core/components/time/FormattedTime.vue';

export default {
  components: {
    FormattedTime,
    BPagination,
    Cleave,
    BCol,
    BRow,
    BButton,
    BTable,
    BBadge,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormCheckbox,
    BFormDatepicker,
    BInputGroup,
    BDropdownItem,
    BDropdown,
    BCard,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      tocEdit: {
        enabled: false,
        isModalOpen: false,
        isAssign: true,
        override: false,

        profile: undefined,
        section: undefined,
        note: '',
        noteCustom: false,
        cost: 0,
        hours: 0,
      },

      reportEdit: {
        scores: { overall: 0, attendance: 0, assignments: 0 },
        weights: { attendance: 0, assignments: 0 },
        classes: {},
      },
      reportEditClasses: [
        // {
        //   id: 1,
        //   selected: 'male',
        //   selected1: 'designer',
        //   prevHeight: 0,
        // }
      ],
      nextTodoId: 1,

      perPage: 5,
      currentPage: 1,

      options: {
        score: {
          numeral: true,
          numeralPositiveOnly: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalScale: 2,
        },
        weight: {
          numeral: true,
          numeralPositiveOnly: true,
          numeralDecimalScale: 2,
        },
        checkins: {
          numeral: true,
          numeralPositiveOnly: true,
          numeralDecimalScale: 0,
        },
      },
    };
  },
  props: {
    isEditOpen: {
      type: Boolean,
    },
    reportId: {
      type: String,
    },
  },
  emits: ['refresh', 'update:is-edit-open'],
  watch: {
    'tocEdit.note'(val) {
      if (val === 'Other') this.tocEdit.noteCustom = true;
    },
  },
  mounted() {
    store
      .dispatch('rosterStoreModule/getComplianceReport', {
        reportId: this.reportId,
        options: { populate: 'section profile' },
      })
      .then((response) => {
        if (response.status === 200) {
          const { data } = response;

          const pl = {
            ...data,
            scores: {
              overall: data.scores.overall * 100,
              attendance: data.scores.attendance * 100,
              assignments: data.scores.assignments * 100,
            },
            weights: {
              attendance: data.weights.attendance * 100,
              assignments: data.weights.assignments * 100,
            },
          };

          this.reportEdit = pl;
          this.reportEditClasses = Object.keys(pl.classes).map((key) => ({ id: key, ...pl.classes[key] }));
          this.initTrHeight();
        }
      })
      .catch((error) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed to fetch report',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.response ? error.response.data.message : error.message,
          },
        });
      });
  },
  created() {
    window.addEventListener('resize', this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight);
  },
  methods: {
    repeateAgain() {
      this.reportEditClasses.push({
        id: (this.nextTodoId += this.nextTodoId),
        checkins: { required: 0, attended: 0 },
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.reportEditClasses.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    closeEdit() {
      this.tocEdit.isModalOpen = false;
      this.$emit('update:is-edit-open', false);
      this.$emit('refresh');
    },
    submitReportEdit() {
      const payload = {
        scores: {
          overall: this.reportEdit.scores.overall / 100,
          attendance: this.reportEdit.scores.attendance / 100,
          assignments: this.reportEdit.scores.assignments / 100,
        },
        weights: {
          attendance: this.reportEdit.weights.attendance / 100,
          assignments: this.reportEdit.weights.assignments / 100,
        },
        classes: this.reportEditClasses.reduce((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {}),
        status: this.reportEdit.status,
      };

      store
        .dispatch('rosterStoreModule/updateComplianceReport', {
          reportId: this.reportId,
          reportData: payload,
        })
        .then((response) => {
          if (response.status === 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Compliance report has been modified!',
                icon: 'EditIcon',
                variant: 'success',
              },
            });
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit report',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        })
        .finally(() => this.$nextTick(() => this.$emit('refresh')));
    },
  },
  computed: {
    totalResults() {
      return this.reportEditClasses.length;
    },
    reportSectionOptions() {
      return [];
      console.log('> computing sec options for cohort', {
        assign: this.isAssign,
        cohort: this.cohortData,
        profile: this.selectedProfile,
        open: this.isEditOpen,
      });
      return this.cohortData.sections
        .filter((section) => {
          console.log('running filter on sec', section);
          if (this.cohortData.sections && this.selectedProfile.sections[section.id]) {
            console.log(`> toc enabled ${section.id}`, this.selectedProfile.sections[section.id].toc?.enabled);
            return this.isAssign
              ? !this.selectedProfile.sections[section.id].toc?.enabled
              : this.selectedProfile.sections[section.id]?.toc?.enabled;
          }
        })
        .map((section) => {
          // check if the student has been assigned to another section, if so, credit the replaced section rather than the cohort-based one.
          const replacedById = this.selectedProfile.sections[section.id]?.replacedBy;
          return { label: section.name, value: replacedById || section.id };
        });
    },
  },
  setup(props) {
    const { resolvePathwayCohortName, resolvePathwayCohortStatusVariant, resolvePathwayCohortVisibilityVariant } = usePathwayCohorts();

    const { resolveCourseSectionName } = useCourseSections();

    const toast = useToast();

    console.log('> USING PROPS', props);

    return {
      toast,
      title,
      moment,
      avatarText,
      resolvePathwayCohortName,
      resolveCourseSectionName,
      resolvePathwayCohortStatusVariant,
      resolvePathwayCohortVisibilityVariant,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
